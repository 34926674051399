import { computed } from 'vue';

export default {
  computed: {
    rules() {
      return {
        budgetAmount: [
          {
            validator: (rule, value, callback) => {
              if (!value) callback('预算不能为空');
              if (this.formData.budgetType == 'LIFETIME_BUDGET') {
                if (this.formData.startTime && this.formData.endTime && value < this.getBudgetMin()) {
                  return callback(`预算在${this.getBudgetMin()}～${this.checkData.adsetBudgetMax}区间`);
                }
              } else if (value && (value < this.checkData.adsetBudgetMin || value > this.checkData.adsetBudgetMax)) {
                return callback(`预算在${this.checkData.adsetBudgetMin}～${this.checkData.adsetBudgetMax}区间`);
              }
              callback();
            },
            trigger: ['blur', 'change'],
          },
        ],
        startTime: [{ required: true, message: '请选择开始时间', trigger: ['change', 'blur'] }],
        endTime: [
          {
            validator: (rule, value, callback) => {
              if (this.formData.budgetType == 'LIFETIME_BUDGET') {
                if (!value) {
                  callback('预算类型是总预算的时候,结束时间必填');
                }
                if (new Date(value).getTime() < new Date(this.formData.startTime).getTime()) {
                  return callback('结束时间不能小于开始时间');
                }
              }
              callback();
            },
            trigger: ['blur', 'change'],
          },
        ],
        bidAmount: [
          {
            validator: (rule, value, callback) => {
              if (value && (value < this.checkData.bidMin || value > this.checkData.bidMax)) {
                return callback(true);
              } else if (!value) {
                return callback(true);
              }
              callback();
            },
            message: `出价在${this.checkData.bidMin}～${this.checkData.bidMax}区间`,
            trigger: ['blur', 'change'],
          },
        ],
        bidStrategy: [{ required: true, message: '请选择竞价类型', trigger: ['change', 'blur'] }],
        optimizationGoal: [{ required: true, message: '请选择优化目标', trigger: ['change', 'blur'] }],
        conversionWindow: [{ required: true, message: '请选择转化窗口', trigger: ['change', 'blur'] }],
      };
    },
  },
};

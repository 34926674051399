<!--
  功能：广告创意
  时间：2025年01月20日 16:01:53
  版本：v1.0
-->
<template>
  <div class="bg-white creative">
    <div class="m-6">
      <el-form
        label-width="100px"
        :model="creative"
        :rules="rules"
        ref="form"
      >
        <el-form-item label="品牌名称">
          <el-input
            disabled
            :value="creative.brandName"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告样式">
          <el-tabs
            type="border-card"
            v-model="creative.type"
            @tab-click="(tab) => selectCreativeType(tab, creative)"
          >
            <el-tab-pane
              label="图像或视频"
              name="WEB_VIEW"
              :disabled="edit"
            >
            </el-tab-pane>
            <el-tab-pane
              label="故事广告"
              name="COMPOSITE"
              :disabled="edit || storageDisabled"
            >
            </el-tab-pane>
            <el-tab-pane
              label="精选广告"
              name="COLLECTION"
              :disabled="edit"
            >
            </el-tab-pane>
            <div>
              <el-form-item
                class="!mb-6"
                prop="infos"
              >
                <upload
                  action="#"
                  :accept="acceptList"
                  multiple
                  :show-file-list="false"
                  :API="API"
                  :value.sync="creative.infos"
                  :limit="limitNum"
                  list-type="picture-card"
                  :selectLimit="selectMediaLimit"
                  @change="(list) => onChangeImg(index, list)"
                  :selectImgIndex.sync="creative.type == 'COMPOSITE' ? null : creative.selectImgIndex"
                  class="mb-6 uploadSnapchat"
                  :isShowDelete="!isLaunch"
                >
                  <div
                    class="leading-4 text-xs border w-full h-full flex flex-col justify-center items-center rounded-md"
                  >
                    <el-button type="primary">点击上传</el-button>
                    <div class="text-gray-700 leading-4 mt-1">
                      <div>尺寸：1080 x 1920</div>
                      <div>视频必须使用 H.264 编码</div>
                      <div>且时长不得超过 180 秒</div>
                      <div>图像格式必须为PNG或JPEG</div>
                    </div>
                  </div>
                </upload>
                <el-input
                  v-if="creative?.infos?.length > 0"
                  placeholder="请输入标题"
                  maxlength="34"
                  show-word-limit
                  v-model="creative.infos[creative.selectImgIndex || 0].headline"
                  @input="handleInput"
                  @change="(value) => onChangeHeadline(value, index, creative)"
                ></el-input>
              </el-form-item>
            </div>
          </el-tabs>
        </el-form-item>
        <span v-if="creative.type == 'COLLECTION'">
          <el-form
            ref="addForm"
            :model="creative.additionalCreativeInfo"
            label-width="100px"
            :rules="addRules"
          >
            <el-form-item
              label="广告缩略图"
              prop="collectionMediaUrl"
            >
              <div class="flex mr-2">
                <div
                  v-for="(img, index) in creative.additionalCreativeInfo.collectionMediaUrl"
                  class="flex mr-4"
                >
                  <div class="relative w-24 h-24">
                    <el-image
                      class="w-24 h-24"
                      :src="img"
                      fit="contain"
                    ></el-image>
                    <i
                      class="el-icon-close absolute -top-1 -right-1 cursor-pointer"
                      @click="deleteMediaUrl(index)"
                      v-if="!isLaunch"
                    ></i>
                  </div>
                </div>
                <el-upload
                  v-if="creative.additionalCreativeInfo?.collectionMediaUrl?.length < 4 && !isLaunch"
                  action="#"
                  :show-file-list="false"
                  :http-request="(file) => uploadImgHandle(file, 'collectionMediaUrl', creative)"
                  :before-upload="(file) => selectImg(file, 'collectionMediaUrl')"
                >
                  <el-image
                    class="w-24 h-24"
                    :src="addImgIcon"
                  ></el-image>
                  <!-- <i class="el-icon-plus avatar-uploader-icon"></i> -->
                </el-upload>
              </div>
            </el-form-item>
          </el-form>
        </span>
        <span v-if="creative.type == 'COMPOSITE'">
          <el-form
            ref="addForm"
            :model="creative.additionalCreativeInfo"
            :rules="addRules"
            label-width="100px"
          >
            <el-form-item
              label="投放版位"
              prop="hasPreview"
            >
              <el-radio-group
                v-model="creative.additionalCreativeInfo.hasPreview"
                @change="(val) => selectHasPreview(val)"
                :disabled="isLaunch"
              >
                <el-radio :label="false">内容之前</el-radio>
                <el-radio :label="true">发现动态</el-radio>
              </el-radio-group>
            </el-form-item>
            <span v-if="creative.additionalCreativeInfo.hasPreview == true">
              <el-form-item
                label="标题"
                prop="headline"
              >
                <el-input
                  v-model="creative.additionalCreativeInfo.headline"
                  placeholder="请输入标题"
                  disabled
                  @change="setCreative"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="背景"
                prop="backgroundMediaUrl"
              >
                <el-upload
                  v-if="!creative.additionalCreativeInfo.backgroundMediaUrl"
                  action="#"
                  :show-file-list="false"
                  :http-request="(file) => uploadImgHandle(file, 'backgroundMediaUrl', creative)"
                  :before-upload="(file) => selectImg(file, 'backgroundMediaUrl')"
                >
                  <!-- <i class="el-icon-plus avatar-uploader-icon"></i> -->
                  <el-button type="text">点击上传</el-button>
                </el-upload>
                <div
                  v-else
                  class="relative w-16 h-24"
                >
                  <el-image
                    class="w-16 h-24"
                    :src="creative.additionalCreativeInfo.backgroundMediaUrl"
                    fit="contain"
                  ></el-image>
                  <i
                    class="el-icon-close absolute -top-1 -right-1 cursor-pointer"
                    @click="creative.additionalCreativeInfo.backgroundMediaUrl = ''"
                    v-if="!isLaunch"
                  ></i>
                </div>
              </el-form-item>
              <el-form-item
                label="logo"
                prop="logoMedia"
              >
                <el-upload
                  v-if="!creative.additionalCreativeInfo.logoMedia"
                  action="#"
                  :show-file-list="false"
                  :http-request="(file) => uploadImgHandle(file, 'logoMedia', creative)"
                  :before-upload="(file) => selectImg(file, 'logoMedia')"
                >
                  <!-- <i class="el-icon-plus avatar-uploader-icon"></i> -->
                  <el-button type="text">点击上传</el-button>
                </el-upload>
                <div
                  v-else
                  class="relative w-24 h-8"
                >
                  <el-image
                    class="w-24 h-12 mt-2"
                    :src="creative.additionalCreativeInfo.logoMedia"
                    fit="contain"
                  ></el-image>
                  <i
                    class="el-icon-close absolute -top-1 -right-1 cursor-pointer"
                    @click="deleteLogoMedia"
                    v-if="!isLaunch"
                  ></i>
                </div> </el-form-item
            ></span>
          </el-form>
        </span>
        <el-form-item
          label="行动号召"
          prop="callToAction"
        >
          <el-select
            v-model="creative.callToAction"
            @change="setCreative"
            :disabled="isLaunch && creative.type === 'COLLECTION'"
          >
            <el-option
              v-for="callToAction in enumConstants.callToActions"
              :key="callToAction.key"
              :label="callToAction.value"
              :value="callToAction.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="推广网址"
          prop="webViewUrl"
        >
          <div class="flex">
            <el-input
              v-model="creative.webViewUrl"
              disabled
              class="flex-1"
            ></el-input>
            <a
              :href="creative.webViewUrl"
              target="_blank"
              class="ml-6"
            >
              <el-button type="text">预览</el-button>
            </a>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <img-cut
      :dialogVisible.sync="dialogVisible"
      :imageSrc="imgUrl"
      :cutImgParams="cutImgParams"
      :fileMsg="fileMsg"
      @uploadImg="uploadCutImg"
    ></img-cut>
  </div>
</template>
<script>
import { upload } from '@adCommonComponent';
import { uploadSingle, uploadImg } from '@/api/common.js';
import getImageSize from '@adCommonComponent/utils/getImageSize.js';
import getVideoMes from '@adCommonComponent/utils/getVideoMes.js';
import imgCut from '../../../components/imgCut/index.vue';
import moment from 'moment';

export default {
  components: { upload, imgCut },
  props: {
    enumConstants: {},
    creativeInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    //单个编辑
    edit: {
      type: Boolean,
      default: false,
    },
    // 复制下一步编辑
    nextEdit: {
      type: Boolean,
      default: false,
    },
    storageDisabled: {
      type: Boolean,
      default: false,
    },
    startTime: {
      type: String,
      default: '',
    },
  },
  data() {
    const validatorCollMedia = (rule, value, callback) => {
      if (!value.length) {
        callback(new Error('请上传缩略图'));
      }
      if (value.length < 2 || value.length > 4) {
        callback(new Error('请上传至少2张至多4张缩略图'));
      }
      callback();
    };
    return {
      API: uploadSingle,
      current: '0',
      selectImgIndex: null,
      addImgIcon: require('@/assets/icon/addImg.png'),
      creative: {
        infos: [{ headline: '' }],
      },
      rules: {
        infos: [
          {
            validator: this.validatorMedia,
            trigger: 'change',
          },
        ],
        callToAction: [
          {
            required: true,
            message: '请选择行动号召',
          },
        ],
      },
      addRules: {
        hasPreview: [{ required: true, message: '请选择投放版位', trigger: 'change' }],
        headline: [{ required: true, message: '请填写标题', trigger: 'change' }],
        backgroundMediaUrl: [{ required: true, message: '请上传背景图', trigger: 'change' }],
        logoMedia: [{ required: true, message: '请上传logo', trigger: 'change' }],
        collectionMediaUrl: [{ validator: validatorCollMedia, required: true }],
        // validator: validatorCollMedia,
        //  message: '请上传缩略图', trigger: 'change'
      },
      imgUrl: '',
      dialogVisible: false,
      initCutParams: {
        outputType: 'png', //输出图片的格式
        infoTrue: true,
      },
      cutImgParams: {},
      fileMsg: {}, //当前选择图片的信息，存下来便于裁剪之后使用

      rules1: {
        collectionMediaUrl: [{ validator: validatorCollMedia, required: true }],
      },
    };
  },
  computed: {
    limitNum() {
      const isCompositeType = this.creative.type === 'COMPOSITE';
      if (((this.edit || this.nextEdit) && !isCompositeType) || (this.edit && isCompositeType && this.isLaunch)) {
        return 1;
      }

      return 10;
    },
    acceptList() {
      if (this.edit && this.creative.type !== 'COMPOSITE') {
        if (this.creative.mediaType === 'VIDEO') {
          return '.mp4,.mov';
        } else if (this.creative.mediaType === 'IMAGE') {
          return '.jpg,.png';
        }
      }
      return '.jpg,.png,.mp4,.mov';
    },
    isLaunch() {
      if (!this.edit) {
        return false;
      }
      const currentTime = moment();
      const targetTime = moment(this.startTime);
      // 开始时间早于当前时间代表投放中
      return targetTime.isBefore(currentTime);
    },
  },
  watch: {
    creativeInfo: {
      handler(val) {
        if (val) {
          this.$set(this, 'creative', Object.assign({}, val));
        } else {
          this.creative = { infos: [{ headline: '' }] };
        }
        // this.creative = val;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    console.log(this.creative, 'mounted creative');
  },
  methods: {
    setCreative() {
      this.$emit('setCreative', this.creative, this.index);
    },
    deleteMediaUrl(index) {
      this.creative.additionalCreativeInfo.collectionMediaUrl.splice(index, 1);
      this.setCreative();
    },
    deleteLogoMedia() {
      this.creative.additionalCreativeInfo.logoMedia = '';
      this.setCreative();
    },
    selectMediaLimit(file) {
      console.log(file);
      return new Promise((relsove, reject) => {
        const raw = file.raw;
        const imgTypes = ['image/png', 'image/jpg', 'image/jpeg'];
        const videoType = ['video/mp4'];
        const fileSize = raw.size / 1024 / 1024;
        // 格式限制
        if (imgTypes.includes(raw.type)) {
          // 图片
          const isLt5MB = fileSize <= 5;
          if (!isLt5MB) {
            return reject('文件限制在5MB以内');
          }

          getImageSize(file.url)
            .then((res) => {
              const { width, height } = res;
              if (width != 1080 || height != 1920) {
                return reject('请选择1080*1920比例的图片');
              }
              relsove();
            })
            .catch((err) => {
              console.log('err', err);
            });
        } else if (videoType.includes(raw.type)) {
          // 视频
          const isLt1GB = fileSize <= 1024;
          if (!isLt1GB) {
            return reject('文件限制在1GB以内');
          }
          getVideoMes(file)
            .then((res) => {
              const { isVideoH264, duration, videoWidth, videoHeight } = res;
              if (videoWidth != 1080 || videoHeight != 1920) {
                return reject('请选择1080*1920比例的视频');
              }
              if (!isVideoH264) {
                return reject('请上传H.264 编码视频');
              }
              if (duration < 3 || duration > 180) {
                return reject('请上传时长在3-180秒中间视频');
              }

              relsove();
            })
            .catch(() => {
              return reject('文件加载失败');
            });
        } else {
          reject('文件格式不正确');
        }
      });
    },
    // 校验素材
    validatorMedia(rule, value, callback) {
      if (!value || !value.length) {
        callback('请上传素材');
      } else if (!value[0].headline) {
        callback(new Error('请填写标题'));
      } else {
        const status = Array.from(new Set(value.map((item) => item.status)));
        // console.log(status, 'status', value);

        if (status.length == 1 && status.includes('success')) {
          callback();
        } else {
          callback(new Error('存在上传中或上传失败的素材'));
        }
      }
    },
    // 对外提供的验证
    creativesValidate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (!valid) {
            return resolve(false);
          }
          if (!this.$refs.addForm) {
            return resolve(true);
          }
          this.$refs.addForm.validate((valid) => {
            if (!valid) {
              return resolve(false);
            }
            return resolve(true);
          });
        });
      });
    },
    clearValidate() {
      if (!this.$refs.form) return;
      let list = [];
      this.errList = [];
      this.$refs.form.map((form, index) => {
        list.push(form.clearValidate());
      });
      return Promise.all(list);
    },
    // 改变故事广告版位
    selectHasPreview(val) {
      this.setCreative();
      if (val && this.creative.infos?.length > 0) {
        this.onChangeHeadline(this.creative.infos[0].headline, this.index, this.creative);
      }
    },
    handleInput(value) {
      // 移除输入中的 '@' 字符
      this.creative.infos[this.creative.selectImgIndex || 0].headline = value.replace(/@/g, '');
    },
    onChangeHeadline(val, index, creative) {
      this.$nextTick(() => {
        //   this.$emit('onChangeHeadline', val, index, creative);
        if (creative.additionalCreativeInfo?.hasPreview) {
          this.$set(creative.additionalCreativeInfo, 'headline', val);
        }
        if (creative.selectImgIndex === null) {
          this.creative.infos.map((item) => {
            this.$set(item, 'headline', val || '');
          });
        } else {
          this.creative.infos.map((item) => {
            if (!item.headline) {
              this.$set(item, 'headline', val || '');
            }
          });
        }
        this.setCreative();
      });
    },
    onChangeImg(index) {
      console.log(index, 'index');
      if (this.creative.type == 'COMPOSITE') {
        this.setCreative();
        return;
      }
      let isNoStatusSuccess = [];
      this.creative.infos.map((item) => {
        if (!item.headline) {
          this.$set(item, 'headline', this.creative.infos[0].headline || '');
        }
        if (item.status != 'success') {
          isNoStatusSuccess.push(item);
        }
      });
      if (isNoStatusSuccess.length == 0) {
        this.$refs.form.validateField(['infos']);
      }
      if (this.creative.selectImgIndex >= this.creative.infos.length) {
        this.creative.selectImgIndex = null;
      }
      this.setCreative();
    },
    // 切换广告样式
    selectCreativeType(tab, creative) {
      creative.infos = [];
      //   图像或者视频
      if (tab.name == 'WEB_VIEW') {
      }
      //   故事广告
      if (tab.name == 'COMPOSITE') {
        this.$set(creative.additionalCreativeInfo, 'hasPreview', false);
        creative.additionalCreativeInfo = {
          hasPreview: false,
          headline: '',
          backgroundMediaUrl: '',
          logoMedia: '',
        };
      }

      //   图像或者视频
      if (tab.name == 'COLLECTION') {
        creative.additionalCreativeInfo = {
          collectionMediaUrl: [],
        };
      }
      this.setCreative();
    },
    // 校验图片 格式
    // async selectImg(file, key) {
    //   console.log(file, key);
    //   const imgTypes = key == 'collectionMediaUrl' ? ['image/png', 'image/jpeg'] : ['image/png'];
    //   let isPx = false;
    //   let isType = imgTypes.includes(file.type);
    //   const fileSize = file.size / 1024 / 1024;
    //   let isSize = key == 'collectionMediaUrl' ? fileSize <= 2 : true;
    //   // 格式限制
    //   if (isType) {
    //     if (!file.url) {
    //       file.url = URL.createObjectURL(file);
    //       this.imgUrl = URL.createObjectURL(file);
    //       this.dialogVisible = true;
    //     }
    //     if (!isSize) {
    //       this.$message.error('图片大小不得超过2M');
    //     }
    //     await getImageSize(file.url)
    //       .then((res) => {
    //         const { width, height } = res;
    //         isPx =
    //           key == 'logoMedia'
    //             ? width == 993 && height == 284
    //             : key == 'collectionMediaUrl'
    //             ? width == 260 && height == 260
    //             : width / height == 3 / 5 && width >= 360;
    //         if (!isPx) {
    //           this.$message.error(
    //             key == 'logoMedia'
    //               ? '请选择993*284的图片'
    //               : key == 'collectionMediaUrl'
    //               ? '请选择260*260的图片'
    //               : '请选择宽高比3/5的图片，图片最小尺寸360*600',
    //           );
    //         }
    //       })
    //       .catch((error) => {});
    //   } else {
    //     this.$message.error('文件格式不正确');
    //   }
    //   return isPx && isType && isSize ? Promise.resolve() : Promise.reject();
    // },
    // 设置裁剪参数
    setCutParams(data) {
      this.cutImgParams = { ...this.initCutParams, ...data };
    },
    // async openCut(file, key) {
    //   await getImageSize(file.url)
    //     .then((res) => {
    //       const { width, height } = res;
    //       console.log(width, height);
    //       let obj = this.validateImgSize(width, height, key);
    //       console.log(obj);
    //       isPx = obj.isPx;
    //       isMin = obj.isMin;
    //       if (!isPx) {
    //         if (!isMin) {
    //           this.$message.error(obj.message);
    //         } else {
    //           if (key == 'logoMedia') {
    //             this.setCutParams({
    //               autoCropWidth: 993,
    //               autoCropHeight: 284,
    //               fixedBox: true,
    //               fixedNumber: [993, 284],
    //               limitMinSize: [993, 284],
    //               infoTrue: false,
    //               full: false,
    //               centerBox: false,
    //               enlarge: 1,
    //             });
    //           } else if (key == 'collectionMediaUrl') {
    //             this.setCutParams({
    //               autoCropWidth: 400,
    //               autoCropHeight: 400,
    //               fixedBox: false,
    //               limitMinSize: [260, 260],
    //               fixedNumber: [1, 1],
    //               enlarge: 1,
    //               infoTrue: false,
    //               full: false,
    //               centerBox: false,
    //             });
    //           } else {
    //             this.setCutParams({
    //               autoCropWidth: 600,
    //               autoCropHeight: 1000,
    //               fixedBox: true,
    //               fixedNumber: [3, 5],
    //               enlarge: 1000 / 800,
    //               infoTrue: true,
    //               full: false,
    //             });
    //           }
    //           this.dialogVisible = true;
    //           this.imgUrl = file.url;
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       this.$message.error('文件读取失败');
    //     });
    // },
    // 校验尺寸
    validateImgSize(width, height, key) {
      let isMin = true, //是否大于最小尺寸,支持裁剪
        isPx = false; // 是否符合尺寸，直接上传
      let message = ''; //提示信息
      isPx =
        key == 'logoMedia'
          ? width == 993 && height == 284
          : key == 'collectionMediaUrl'
          ? width >= 260 && height >= 260 && width / height == 1
          : width / height == 3 / 5 && width >= 360;
      if (!isPx) {
        if (key == 'logoMedia' && (width < 993 || height < 284)) {
          isMin = false;
          message = '图片最小尺寸为 993*284';
        } else if (key == 'collectionMediaUrl' && (width < 260 || height < 260)) {
          isMin = false;
          message = '图片最小尺寸为260*260';
        } else if (key == 'backgroundMediaUrl' && width < 360 && height < 600) {
          isMin = false;
          message = '图片最小尺寸为360*600';
        }
      }
      return { isPx, isMin, message };
    },
    // 选择图片
    async selectImg(file, key) {
      console.log(file, key, 'selectImg');
      const imgTypes = key == 'collectionMediaUrl' ? ['image/png', 'image/jpeg'] : ['image/png'];
      this.fileMsg = {
        name: file.name,
        key: key,
      };
      let isPx = false;
      let isMin = false;
      let isType = imgTypes.includes(file.type);
      const fileSize = file.size / 1024 / 1024;
      let isSize = key == 'collectionMediaUrl' ? fileSize <= 2 : true;
      console.log(isType, file.type, key, 'file.type');

      // 格式限制
      if (isType || (key != 'collectionMediaUrl' && file.type == 'image/jpeg')) {
        if (!file.url) {
          file.url = URL.createObjectURL(file);
        }
        if (!isSize) {
          this.$message.error('图片大小不得超过2M');
        }
        await getImageSize(file.url)
          .then((res) => {
            const { width, height } = res;
            console.log(width, height);
            let obj = this.validateImgSize(width, height, key);
            console.log(obj);
            isPx = obj.isPx;
            isMin = obj.isMin;
            if (!isPx) {
              if (!isMin) {
                this.$message.error(obj.message);
              } else {
                if (key == 'logoMedia') {
                  this.setCutParams({
                    autoCropWidth: 993,
                    autoCropHeight: 284,
                    fixedBox: true,
                    fixedNumber: [993, 284],
                    limitMinSize: [993, 284],
                    infoTrue: false,
                    full: false,
                    centerBox: false,
                    enlarge: 1,
                  });
                } else if (key == 'collectionMediaUrl') {
                  this.setCutParams({
                    autoCropWidth: 400,
                    autoCropHeight: 400,
                    fixedBox: false,
                    limitMinSize: [260, 260],
                    fixedNumber: [1, 1],
                    enlarge: 1,
                    infoTrue: false,
                    full: false,
                    centerBox: false,
                  });
                } else {
                  this.setCutParams({
                    autoCropWidth: 600,
                    autoCropHeight: 1000,
                    fixedBox: true,
                    fixedNumber: [3, 5],
                    enlarge: 1000 / 800,
                    infoTrue: true,
                    full: false,
                  });
                }
                this.dialogVisible = true;
                this.imgUrl = file.url;
              }
            }
          })
          .catch((error) => {
            this.$message.error('文件读取失败');
          });
      } else {
        this.$message.error('文件格式不正确');
      }
      console.log(isPx && isType && isSize && isMin);
      return isPx && isType && isSize && isMin ? Promise.resolve() : Promise.reject();
    },
    // 接收裁剪过的图片上传
    uploadCutImg(file, key) {
      this.uploadImgHandle({ file: file }, key, this.creative);
    },
    // 上传图片
    uploadImgHandle(file, key, creative) {
      uploadImg(file)
        .then((res) => {
          if (key == 'collectionMediaUrl') {
            if (creative.additionalCreativeInfo[key].length >= 4) return;
            creative.additionalCreativeInfo[key].push(res.data.url);
          } else {
            creative.additionalCreativeInfo[key] = res.data.url;
          }
          this.setCreative();
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss">
.creative .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child span.el-icon-close {
  display: none !important;
}
.el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item div {
  display: inline-block;
}
.creative .el-tabs__new-tab {
  display: none;
}
.uploadSnapchat {
  .el-upload--picture-card {
    width: 150px;
    height: 240px;
  }
}
.el-upload--picture-card {
  width: 100px;
  height: 100px;
}
</style>

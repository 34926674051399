<template>
  <div class="bg-[#fff] mb-6 p-4">
    <p class="font-lg font-bold py-3">预算&排期</p>
    <el-form
      ref="form"
      :model="formData"
      label-width="120px"
      :rules="rules"
      :disabled="nextEdit && disabled"
    >
      <el-form-item
        label="组预算"
        prop="budgetAmount"
      >
        <el-input
          v-model="formData.budgetAmount"
          style="width: 320px"
          type="number"
          placeholder="请输入预算金额"
          @blur="changeBudget"
          >" >
          <el-select
            v-model="formData.budgetType"
            placeholder=""
            slot="prepend"
            class="w-32"
            @change="setTableData('budgetType', formData.budgetType)"
          >
            <el-option
              v-for="item in budgetTypes"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
          <span slot="append">{{ currency }}</span>
        </el-input>
      </el-form-item>
      <div class="flex">
        <el-form-item
          label="组排期"
          prop="startTime"
        >
          <el-date-picker
            v-model="formData.startTime"
            type="datetime"
            :disabled="edit"
            placeholder="开始时间"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="changeStartTime"
          ></el-date-picker>
        </el-form-item>
        <span class="mx-2">~</span>
        <el-form-item
          label=""
          label-width="0"
          prop="endTime"
        >
          <el-date-picker
            v-model="formData.endTime"
            type="datetime"
            placeholder="结束时间"
            @change="selectEndTime"
            :picker-options="pickerOptions1"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </el-form-item>
      </div>
      <el-form-item
        label="目标"
        prop="optimizationGoal"
      >
        <div class="w-24 inline-block text-center trance">网站转化</div>
        <el-select
          v-model="formData.optimizationGoal"
          placeholder=""
          style="width: 200px"
          :disabled="edit"
          @change="setTableData('optimizationGoal', formData.optimizationGoal)"
        >
          <el-option
            v-for="item in optimizationGoals"
            :key="item.key"
            :label="item.value"
            :value="item.key"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="优化期限"
        prop="conversionWindow"
      >
        <el-select
          v-model="formData.conversionWindow"
          placeholder=""
          class="w-96"
          @change="setTableData('conversionWindow', formData.conversionWindow)"
          :disabled="edit"
        >
          <el-option
            v-for="item in conversionWindows"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="竞价策略"
        prop="bidStrategy"
      >
        <el-radio-group
          v-model="formData.bidStrategy"
          @change="selectBid"
        >
          <el-radio
            :label="item.key"
            v-for="item in bidStrategys"
            >{{ item.value }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label=""
        v-if="formData.bidStrategy != 'AUTO_BID'"
        prop="bidAmount"
      >
        <el-input
          v-model="formData.bidAmount"
          placeholder="请输入出价金额"
          style="width: 240px"
          @blur="changeBid"
          type="number"
        >
          <span slot="append">{{ currency }}/转化</span>
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { queryConfigDataCheck } from '@/api/multiPlatform';
import moment from 'moment';
import { mapState } from 'vuex';
import  rule  from './rule';
export default {
  props: {
    value: Object,
    enumConstants: Object,
    allAdAccountId: Array,
    params: Object,
    edit: Boolean,
    nextEdit: Boolean,
    editData: Object,
    partDisabled: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [rule],
  data() {
    return {
      formData: {
        budgetAmount: 20,
        budgetType: 'DAILY_BUDGET',
        optimizationGoal: 'PIXEL_PURCHASE',
        bidStrategy: 'AUTO_BID',
        bidAmount: '',
        conversionWindow: 'SWIPE_28DAY_VIEW_1DAY',
        startTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        endTime: '',
      },
      splitTableMsg: {
        campaignName: '',
        adGroups: [],
      },
      checkData: {},
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      pickerOptions1: {
        disabledDate: this.disabledDate,
      },
    };
  },
  computed: {
    ...mapState('user', ['userInfo']),
    bidStrategys() {
      return this.enumConstants.bidStrategies || [];
    },
    budgetTypes() {
      return this.enumConstants.budgetTypes || [];
    },
    conversionWindows() {
      return this.enumConstants.conversionWindows || [];
    },
    optimizationGoals() {
      if(this.edit){
        return this.enumConstants.optimizationGoals || [];
      }
      if(this.nextEdit){
        const result = this.enumConstants.optimizationGoals || [];
        // 判断这个广告组下边的广告有没有故事广告，有的话，加购和页面浏览量是不可以被点击的
        if (result?.length) {
            result.forEach((item) => {
              if (item.key === 'PIXEL_ADD_TO_CART' || item.key === 'PIXEL_PAGE_VIEW') {
                this.$set(item, 'disabled', this.partDisabled);
              } else {
                this.$set(item, 'disabled', false);
              }
            }); 
        }
        return result;
      }
      let list = [];
      console.log(this.params.creatives);
      let creativeTypeList = [...new Set(this.params?.creatives?.map((v) => v.type))];
      // console.log(creativeTypeList);
      creativeTypeList.forEach((item) => {
        let cur = this.enumConstants.creativeTypes.find((v) => v.key == item);
        cur ? list.push(cur.listRef) : null;
      });
      console.log('optimizationGoals',list,this.intersectionByKey(list, 'key'));
      return this.intersectionByKey(list, 'key');
    },
    activeAccount() {
      return this.allAdAccountId?.find((v) => v.accountId == this.params.adAccountId) || {};
    },
    currency() {
      if(this.edit){
        return this.editData.statistics.currency || 'USD';
      }
      return this.activeAccount?.currencyCode || 'USD';
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val && Object.keys(val).length) {
          console.log(val,'value');
          this.formData = { ...val };
        }
      },
    },
  },
  methods: {
    intersectionByKey(arrays, key) {
      if (arrays.length === 0) return [];

      // Start with the first array's keys as a Set for comparison
      let result = arrays[0].map((item) => item[key]);

      // Reduce to find the intersection
      for (let i = 1; i < arrays.length; i++) {
        const currentKeys = new Set(arrays[i].map((item) => item[key]));
        result = result.filter((value) => currentKeys.has(value));
      }

      // Filter original objects based on the intersected keys
      return arrays.map((array) => array.filter((item) => result.includes(item[key])))[0];
    },
    disabledDate(time) {
      return time.getTime() < Date.now() - 8.64e7 || time.getTime() < new Date(this.formData.startTime).getTime();
    },
    // 根据结束日期，计算最小预算
    getBudgetMin() {
      let start = moment(this.formData.startTime).format('YYYY-MM-DD');
      let end = moment(this.formData.endTime).format('YYYY-MM-DD');
      let days = moment(end).diff(start, 'days');
      return this.checkData.adsetBudgetMin * (days + 1);
    },
    // 获取校验规则
    getConfigCheck() {
      console.log('getConfigCheck--------');
      
      queryConfigDataCheck({ platform: 'snapchat', currency: this.currency }).then(
        (res) => {
          console.log(res);
          this.checkData = res.data;
        },
      );
    },
    // 修改预算
    changeBudget(v) {
      console.log(v);
      this.formData.budgetAmount = (this.formData.budgetAmount / 1).toFixed(2);
      //   this.splitView();
      this.setTableData('budgetAmount', this.formData.budgetAmount);
      this.setTableData('startTime', this.formData.startTime);
      this.setTableData('endTime', this.formData.endTime);
      this.setTableData('budgetType', this.formData.budgetType);
    },
    // 修改出价
    changeBid(v) {
      this.formData.bidAmount = (this.formData.bidAmount / 1).toFixed(2);
      //   this.splitView();
      this.setTableData('bidAmount', this.formData.bidAmount);
      this.setTableData('bidStrategy', this.formData.bidStrategy);
    },
    // 修改开始时间
    changeStartTime() {
      this.setTableData('startTime', this.formData.startTime);
      this.setTableData('endTime', this.formData.endTime);
      this.setTableData('budgetAmount', this.formData.budgetAmount);
    },
    // 总预算设置结束日期的时候校验预算
    selectEndTime() {
      this.$refs.form.validateField('budgetAmount');
      //   this.splitView();
      this.setTableData('startTime', this.formData.startTime);
      this.setTableData('endTime', this.formData.endTime);
      this.setTableData('budgetAmount', this.formData.budgetAmount);
    },
    // 选择竞价策略
    selectBid(v) {
      if (v == 'AUTO_BID') {
        this.formData.bidAmount = null;
        this.setTableData('bidAmount', null);
      }
      //   this.splitView();
      this.setTableData('bidStrategy', this.formData.bidStrategy);
    },
    // 对外验证
    validate() {
      let list = [];
      list.push(this.$refs.form.validate());
      return Promise.all(list);
    },
    // 设置表格单项数据
    setTableData(key, value) {
      this.$emit('input', this.formData);
      this.$refs.form.validate((validate) => {
        if (validate) {
          this.$emit('setTableData', key, value);
          // this.splitTableMsg.adGroups.forEach((group) => {
          //   group[key] = value;
          // });
        }
      });
    },
  },
  created() {
    this.getConfigCheck();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
 .el-checkbox__original {
  display: none !important;
}

.el-checkbox:focus:not(.is-focus):not(:active):not(.is-disabled) .el-checkbox__inner {
    box-shadow: none !important;
}
.el-form-item .el-select .el-input__inner {
  padding-left: 90px;
}

.campaignName {
  display: flex;
  align-items: center;
  .label {
    flex: 100px 0 0;
  }
  .input {
    flex: 1;
  }
}
.trance {
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  height: 40px;
  border-right: none;
  margin-right: -3px;
  background-color: #f5f7fa;
  color: #909399;
}
</style>

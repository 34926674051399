import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState('user', ['userInfo']),
  },
  // 组件方法
  methods: {
    // 处理受众
    setAdGroup(v) {
      v.geos = v.geos.map((geo) => {
        if (geo.countryCode) return geo;
        return { countryCode: geo };
      });
      console.log(v.interests, 'v.interests');
      if (v.interests) {
        v.interests = [
          {
            categoryId: Array.from(new Set([].concat(...Object.values(v.interests)))),
            operation: 'INCLUDE',
          },
        ];
      }
      v.segments = v.segments?.filter((segments) => {
        if (segments.segmentId.length) {
          return segments;
        }
      });
      return v;
    },
    // 重置素材名字
    formatterName(str, newmd5) {
      const lastIndex = str.lastIndexOf('.');
      let strList = [];
      // 根据最后一个点进行分割
      // 存在最后一个点
      if (lastIndex !== -1) {
        strList = [str.slice(0, lastIndex), str.slice(lastIndex + 1)];
      }
      if (strList.length === 2 && newmd5) {
        const nameSplitList = this.splitStringByMultipleDelimiters(strList[0]);
        // 符合规则
        if (nameSplitList.length === 3) {
          const isNumeric = (numStr) => /^\d+$/.test(numStr);
          if (isNumeric(nameSplitList[0])) {
            return `${nameSplitList[0]}#${nameSplitList[1]}#${newmd5}`;
          } else {
            const userObj = this.userInfo.data;
            return `${userObj.id}#${userObj.name}#${newmd5}`;
          }
        } else if (nameSplitList.length > 3) {
          let list = nameSplitList.slice(0, 2);
          return list.join('#') + '#' + newmd5;
        } else {
          const userObj = this.userInfo.data;
          return `${userObj.id}#${userObj.name}#${newmd5}`;
        }
      } else {
        return str;
      }
    },

    // formatterName(str, newmd5) {
    //   if (!str) {
    //     return;
    //   }
    //   const strList = str.split('.');
    //   if (strList.length === 2 && newmd5) {
    //     const nameSplitList = this.splitStringByMultipleDelimiters(strList[0]);
    //     // 符合规则
    //     if (nameSplitList.length === 3) {
    //       const timeStamp = new Date().getTime();
    //       return `${nameSplitList[0]}#${nameSplitList[1]}#${newmd5}`;
    //     } else if (nameSplitList.length > 3) {
    //       let name = '';
    //       let list = nameSplitList.slice(0, 2);
    //       //   nameSplitList.forEach((item, index) => {
    //       //     if (index === 0) {
    //       //       name = item;
    //       //     } else if (index > 0 && index !== 2 && index !== nameSplitList.length - 1) {
    //       //       name = name + '#' + item;
    //       //     } else if (index === 2) {
    //       //       name = name + '#' + newmd5;
    //       //     } else {
    //       //       //   const timeStamp = new Date().getTime();
    //       //       //   name = name + '#' + item;
    //       //     }
    //       //   });
    //       return list.join('#') + '#' + newmd5;
    //       //   return `${name}.${strList[1]}`;
    //     } else {
    //       const userObj = this.userInfo.data;
    //       const timeStamp = new Date().getTime();
    //       return `${userObj.id}#${userObj.name}#${newmd5}`;
    //     }
    //   } else {
    //     return str;
    //   }
    // },
    // 校验素材名字
    splitStringByMultipleDelimiters(str) {
      // 使用正则表达式匹配所有 -、* 或 # 字符，并启用全局标志
      const delimiters = /[-*#]/g;
      // 使用字符串的 split 方法，但传入一个正则表达式作为分隔符
      // 注意：split 方法会将分隔符本身从结果数组中排除
      return str.split(delimiters);
    },
  },
};
